import type { FC } from 'react'

interface LabelProps {
  text?: string | null
  required?: boolean
}

export const Label: FC<LabelProps> = ({ text, required }) => {
  return (
    <label
      htmlFor={text ?? undefined}
      className="mb-3 block cursor-pointer text-sm font-semibold leading-none text-gray-600"
    >
      {text}

      {required && <span className="text-rose-500"> *</span>}
    </label>
  )
}
