import type { FC, ReactNode } from 'react'
import { useState } from 'react'

interface TooltipProps {
  className?: string
  tip: ReactNode
}

export const Tooltip: FC<TooltipProps> = ({ tip, className }) => {
  const [showTip, setShowTip] = useState(false)

  const handleMouseOver = () => {
    setShowTip(true)
  }

  const handleMouseOut = () => {
    setShowTip(false)
  }

  const handleClick = () => {
    setShowTip(!showTip)
  }

  return (
    <div
      className={`relative inline-flex h-6 w-6 cursor-pointer items-center justify-center rounded-full bg-slate-100 text-xs font-semibold text-slate-500 ${className}`}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onClick={handleClick}
    >
      ?
      {showTip && (
        <div
          className="absolute -left-1/2 z-10 mt-2 w-32 translate-x-1/2 transform rounded-md bg-black px-4 py-2 text-sm text-white shadow-lg"
          style={{ bottom: '100%' }}
        >
          {tip}
        </div>
      )}
    </div>
  )
}
