import dynamic from 'next/dynamic'
import { Bean } from 'lucide-react'
import { FaSeedling } from 'react-icons/fa6'

// Dynamically import useLottie to prevent SSR issues
const DynamicLottie = dynamic(
  async () => {
    const { useLottie } = await import('lottie-react')
    return (props: any) => {
      const { View } = useLottie(props)
      return <>{View}</>
    }
  },
  { ssr: false }, // Disable server-side rendering for this component
)

const AgroSocialLogo = () => {
  return (
    <DynamicLottie
      // eslint-disable-next-line @typescript-eslint/no-require-imports
      animationData={require('./loading.json')}
      loop={false}
      style={{ width: 120, height: 110 }}
    />
  )
}

export const Config = {
  loading: '',
  isMarketPlace: false,
  colors: {
    primary: '#005d62',
    iconColor: '#005d62',
    selectedIconColor: '#003944',
  },
  keywords: {
    likes: 'Seeds',
  },
  icons: {
    likeDefault: <Bean className="h-6 w-6" color="#005d62" />,
    likePressed: <FaSeedling size={20} color="#005d62" />,
  },
  login: {
    toRadialColor: '#003944',
    fromRadialColor: '#008075',
    loginImage: <AgroSocialLogo />,
  },
  buttons: {
    borderRadius: 50,
    textColor: '#bcf565',
    fontWeight: '600',
  },
}
