import type { Config } from '../config/gealium/config'

export type Apps = 'gealium' | 'agrosocial'
export type AppEnv = 'local' | 'test' | 'prod' | 'dev'

type AppConfiguration = Record<
  string,
  {
    theme: typeof Config
    tailwind: Record<string, any>
  }
>

const appConfigurations: AppConfiguration = {
  gealium: {
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    theme: require('../config/gealium/config').Config,
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    tailwind: require('../config/gealium/tailwindColors').tailWindColors,
  },
  agrosocial: {
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    theme: require('../config/agrosocial/config').Config,
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    tailwind: require('../config/agrosocial/tailwindColors').tailWindColors,
  },
}

class ThemeProvider {
  getAppName(): Apps {
    return (
      // eslint-disable-next-line no-restricted-properties
      (process.env.NEXT_PUBLIC_APP_NAME?.toLowerCase() as Apps) || 'gealium'
    )
  }

  getMarketPlaceConfig(): typeof Config {
    const appName = this.getAppName()
    return appConfigurations[appName]?.theme || ({} as typeof Config)
  }

  getMarketPlaceTailwindColors(): Record<string, any> {
    const appName = this.getAppName()
    return appConfigurations[appName]?.tailwind || {}
  }
}

export const themeProvider = new ThemeProvider()
export const sodiumConfig = themeProvider.getMarketPlaceConfig()
